<template>
  <v-form ref="invoiceForm" v-model="formValid">
    <v-container fluid v-if="!!customer && !!customer.dataInvoice || !!value">
      <v-row>
        <v-col>
          <v-text-field
            v-model="companyNameOrLastname"
            filled
            dense
            rounded
            hide-details="auto"
            label="Ragione sociale o cognome"
            prepend-icon="mdi-account-arrow-right"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="firstname"
            filled
            dense
            rounded
            hide-details="auto"
            label="Nome (se persona fisica)"
            prepend-icon="mdi-account"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="address"
            filled
            dense
            rounded
            hide-details="auto"
            :rules="[presenceRule]"
            label="Indirizzo"
            prepend-icon="mdi-map-marker-outline"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="cap"
            filled
            dense
            rounded
            hide-details="auto"
            :rules="[presenceRule]"
            label="CAP"
            prepend-icon="mdi-code-tags"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="city"
            filled
            dense
            rounded
            hide-details="auto"
            :rules="[presenceRule]"
            label="Città"
            prepend-icon="mdi-city-variant-outline"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="province"
            filled
            dense
            rounded
            hide-details="auto"
            :rules="[presenceRule]"
            label="Provincia"
            prepend-icon="mdi-map-search-outline"
            maxlength="2"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="vatNumber"
            filled
            dense
            rounded
            hide-details="auto"
            label="Partita IVA"
            :rules="[presenceRule]"
            prepend-icon="mdi-file-code-outline"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="fiscalCode"
            filled
            dense
            rounded
            hide-details="auto"
            label="Codice Fiscale"
            prepend-icon="mdi-card-account-details"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="mail"
            filled
            dense
            rounded
            hide-details="auto"
            label="Email PEC"
            prepend-icon="mdi-email"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="destinataryCode"
            filled
            dense
            rounded
            hide-details="auto"
            label="Codice Univoco Destinatario"
            prepend-icon="mdi-qrcode"
            @input="emitInput"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>


    </v-container>
    <div class="d-flex justify-center" v-else>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </v-form>
</template>

<script>
import dataInvoiceService from "@/services/invoices/dataInvoices.service.js";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentConditionService from "@/services/paymentTerms/paymentConditions.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
import Vue from "vue";

export default {
  name: "InvoiceDataForm",
  data: function() {
    return { 
      formValid: false,
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      companyNameOrLastname: undefined,
      firstname: undefined,
      address: undefined,
      cap: undefined,
      city: undefined,
      province: undefined,
      vatNumber: undefined,
      fiscalCode: undefined,
      mail: undefined,
      destinataryCode: undefined,
      paymentTypeNatureExemptionId: undefined,
      paymentTypeNatureExemptionItems: [],
      paymentConditionId: undefined,
      paymentConditionItems: [],
      paymentTermId: undefined,
      paymentTermsItems: [],
      noIva: undefined,
    }
  },
  props: {
    value: { 
      type: Object,
      default: () => ({})
    },
    customer: {
      type: Object,
      default: () => ({})
    },
    valid: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
      default: () => new Vue()
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mounted: async function() {
    this.$emit('update:valid', this.localValid)
    this.updateLocalValue()
    this.$emit('input', this.localValue)
    this.fetchPaymentTerms()
    let self = this;
    this.bus.$on('invoice-update-parent-child',(data) => self.updateFields(data))
  },
  methods: {
    updateLocalValue() {
      if(!!this.customer && !!this.customer.dataInvoice) {
        if(!!this.customer.dataInvoice.lastname) this.companyNameOrLastname = this.customer.dataInvoice.lastname
        else this.companyNameOrLastname = this.customer.lastname

        if(!!this.customer.dataInvoice.firstname) this.firstname = this.customer.dataInvoice.firstname
        else this.firstname = this.customer.firstname
        if(!!this.customer.dataInvoice.address) this.address = this.customer.dataInvoice.address
        if(!!this.customer.dataInvoice.cap) this.cap = this.customer.dataInvoice.cap
        if(!!this.customer.dataInvoice.city) this.city = this.customer.dataInvoice.city
        if(!!this.customer.dataInvoice.province) this.province = this.customer.dataInvoice.province
        if(!!this.customer.dataInvoice.vatNumber) this.vatNumber = this.customer.dataInvoice.vatNumber
        if(!!this.customer.dataInvoice.fiscalCode) this.fiscalCode = this.customer.dataInvoice.fiscalCode
        if(!!this.customer.dataInvoice.mail) this.mail = this.customer.dataInvoice.mail
        if(!!this.customer.dataInvoice.destinataryCode) this.destinataryCode = this.customer.dataInvoice.destinataryCode
        if(!!this.customer.dataInvoice.paymentConditionId) this.paymentConditionId = this.customer.dataInvoice.paymentConditionId
        if(!!this.customer.dataInvoice.paymentTermId) this.paymentTermId = this.customer.dataInvoice.paymentTermId
        if(!!this.customer.dataInvoice.paymentTypeNatureExemptionId) this.paymentTypeNatureExemptionId = this.customer.dataInvoice.paymentTypeNatureExemptionId
      }
    },
    emitInput() {
      this.$emit('input', this.localValue)
    },

    fetchPaymentTerms(){
      new Promise(async (resolve, reject) =>{

        let resultPaymentTermsItems = await paymentTermService.list()
        this.paymentTermsItems =  [...resultPaymentTermsItems.rows]

        let resultPaymentConditionItems = await paymentConditionService.list()
        this.paymentConditionItems =  [...resultPaymentConditionItems.rows]


        let resultPaymentTypeNatureExemptionItems = await paymentTypeNatureExemptionService.list()
        this.paymentTypeNatureExemptionItems =  [...resultPaymentTypeNatureExemptionItems.rows]     
        
        this.docPrintItems = dataInvoiceService.listDocPrint();
        resolve();
      })
    },
    updateFields(newVal){
      this.$nextTick(() => {
        this.companyNameOrLastname = newVal.companyNameOrLastname
        this.firstname = newVal.firstname;
        this.address = newVal.address;
        this.cap = newVal.cap;
        this.city = newVal.city;
        this.province = newVal.province;
        this.vatNumber = newVal.vatNumber;
        this.fiscalCode = newVal.fiscalCode;
        this.mail = newVal.mail;
        this.destinataryCode = newVal.destinataryCode;
      });
    }
  },
  watch: {
    customer() {
      this.updateLocalValue()
      this.$emit('input', this.localValue)
    },
    localValid() {
      this.$emit('update:valid', this.localValid)
    },
    value(){
      this.updateLocalValue()
    }
  },
  computed: {
    localValid() {
      return this.formValid
    },
    localValue() {
      return {
        companyNameOrLastname: this.companyNameOrLastname,
        firstname: this.firstname,
        address: this.address,
        cap: this.cap,
        city: this.city,
        province: this.province,
        vatNumber: this.vatNumber,
        fiscalCode: this.fiscalCode,
        mail: this.mail,
        destinataryCode: this.destinataryCode,
        paymentConditionId: this.paymentConditionId,
        paymentTermId: this.paymentTermId,
        paymentTypeNatureExemptionId: this.paymentTypeNatureExemptionId,
      }
    },
  },
}
</script>

<style>

</style>